import { container } from 'tsyringe'
import Vue from 'vue'
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import store from '@/app/ui/store'
import { RoutePresenter } from '@/app/ui/presenters/RoutePresenter'
import { LetterCode, OriginRoute, Route } from '@/domain/entities/Route'
import { Utils } from '@/app/infrastructures/misc'

interface Options {
  value: 'id' | 'name' | 'route' | 'name-lc' // set dropdown value to id, name, route, or name with lc (city name/district name), default: id
}
export interface Data {
  value: string | number
  label: string
}
export interface RouteState {
  isLoadingOrigin: boolean
  isLoadingCityDistrict: boolean
  isLoadingCity: boolean
  isLoadingDistrict: boolean
  isLoadingRoute: boolean
  isLoadingLetterCode: boolean
  cityData: Data[]
  districtData: Data[]
  cityDistrictData: Data[]
  originData: Data[]
  routeData: Route[]
  letterCodes: LetterCode[]
}

@Module({ namespaced: true, store, name: 'route', dynamic: true })
class RouteController extends VuexModule implements RouteState {
  private presenter: RoutePresenter = container.resolve(RoutePresenter)
  public isLoadingOrigin = false
  public isLoadingCityDistrict = false
  public isLoadingCity = false
  public isLoadingDistrict = false
  public isLoadingRoute = false
  public isLoadingLetterCode = false
  public cityData: Data[] = []
  public districtData: Data[] = []
  public cityDistrictData: Data[] = []
  public originData: Data[] = []
  public routeData: Route[] = []
  public letterCodes: LetterCode[] = []

  @Action({ rawError: true })
  public getOriginList(options?: Options) {
    this.presenter
      .getActiveOrigin()
      .then(routes => {
        this.setCityList({ routes, options })
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Origin Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoadingOrigin(false)
      })
  }

  @Action({ rawError: true })
  public getCityDistrictList(data: { city: string; options?: Options }) {
    this.setLoadingCityDistrict(true)

    this.presenter
      .getAllDistrict(data.city.replace(/\s\(([A-Z]{3}\))$/, ''))
      .then(routes => {
        this.setDistrictList({
          routes,
          options: { value: data.options?.value || 'id' },
        })
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch City District Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoadingCityDistrict(false)
      })
  }

  @Action({ rawError: true })
  public getCityList(options?: Options) {
    this.presenter
      .getAllCity()
      .then(routes => {
        this.setCityList({ routes, options })
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch City Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoadingCity(false)
      })
  }

  @Action({ rawError: true })
  public getDistrictList(data: { city: string; options?: Options }) {
    this.setLoadingDistrict(true)

    this.presenter
      .getAllDistrict(data.city.replace(/\s\(([A-Z]{3}\))$/, ''))
      .then(routes => {
        this.setDistrictList({
          routes,
          options: { value: data.options?.value || 'id' },
        })
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch District Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoadingDistrict(false)
      })
  }

  @Action({ rawError: true })
  public getRouteList(params: Record<string, string>): void {
    this.setLoadingRoute(true)

    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )
    this.presenter
      .getAllRoute(formattedParams)
      .then(res => {
        this.setRouteList(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Route Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoadingRoute(false)
      })
  }

  @Action({ rawError: true })
  public getLetterCodes(): void {
    this.setLoadingLetterCode(true)

    this.presenter
      .getLetterCodes()
      .then(res => {
        this.setLetterCode(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch 3LC Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoadingLetterCode(false)
      })
  }

  @Mutation
  private setCityList(data: { routes: OriginRoute[]; options?: Options }) {
    this.cityData = data.routes.map(route => {
      if (data.options?.value === 'name') {
        return {
          label: route.city as string,
          value: route.city as string,
        }
      }

      if (data.options?.value === 'route') {
        return {
          label: route.route as string,
          value: route.route as string,
        }
      }

      if (data.options?.value === 'name-lc') {
        return {
          label: `${route.city as string} (${route.letterCode as string})`,
          value: `${route.city as string} (${route.letterCode as string})`,
        }
      }

      return {
        label: route.city as string,
        value: route.id as number,
      }
    })
  }

  @Mutation
  private setDistrictList(data: { routes: OriginRoute[]; options?: Options }) {
    this.districtData = data.routes.map(district => {
      return {
        label: district.route as string,
        value:
          data.options?.value === 'name'
            ? (district.route as string)
            : (district.id as number),
      }
    })
  }

  @Mutation
  private setLoadingOrigin(bool: boolean) {
    this.isLoadingOrigin = bool
  }

  @Mutation
  private setLoadingCityDistrict(bool: boolean) {
    this.isLoadingCityDistrict = bool
  }

  @Mutation
  private setLoadingCity(bool: boolean) {
    this.isLoadingCity = bool
  }

  @Mutation
  private setLoadingDistrict(bool: boolean) {
    this.isLoadingDistrict = bool
  }

  @Mutation
  private setRouteList(data: Route[]): void {
    this.routeData = data
  }

  @Mutation
  private setLoadingRoute(bool: boolean): void {
    this.isLoadingRoute = bool
  }

  @Mutation
  private setLoadingLetterCode(bool: boolean): void {
    this.isLoadingLetterCode = bool
  }

  @Mutation
  private setLetterCode(data: LetterCode[]): void {
    this.letterCodes = data
  }
}

export default getModule(RouteController)
