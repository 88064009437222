


























































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import Button from '@/app/ui/components/Button/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import DateTimePicker from '@/app/ui/components/DateTimePicker/index.vue'
import Badge from '@/app/ui/components/Badge/index.vue'
import Loading from '@/app/ui/components/Loading/index.vue'
import EmptyIllustration from '@/app/ui/assets/EmptyState/no_data.vue'
import ExpandIcon from '@/app/ui/assets/expand_icon.vue'
import PopupBox from '../components/PopupBox/index.vue'
import { Utils } from '@/app/infrastructures/misc'
import {
  EnumSort,
  EnumStatus,
} from '@/app/infrastructures/misc/Constants/manageCaptain'
import { ManageCaptain } from '@/domain/entities/ManageCaptain'
import controller from '@/app/ui/controllers/ManageCaptainController'
import dayjs from 'dayjs'
import dayjsID from 'dayjs/locale/id'
import { DataObject } from '@/app/ui/components/DataTableV2/type'
import { Location } from 'vue-router'
import routeController from '@/app/ui/controllers/RouteController'
import { LetterCode } from '@/domain/entities/Route'

interface Dropdown {
  value: string | number
  label: string
}

interface IParams {
  page: number
  per_page: number
  keyword: string
  status: Dropdown
  letter_code: Dropdown
  sk_start_at: string
  sk_end_at: string
  sort: Dropdown
}

@Component({
  components: {
    Button,
    TextInput,
    DropdownSelect,
    DataTableV2,
    PaginationNav,
    DateTimePicker,
    Badge,
    Loading,
    EmptyIllustration,
    ExpandIcon,
    PopupBox,
  },
})
export default class ManageCaptainList extends Vue {
  controller = controller
  routeController = routeController
  popupFilterVisible = false
  appliedFilter = 0

  statusOptions: Array<Dropdown> = [
    { label: 'Pilih Status', value: '' },
    { label: 'Active', value: EnumStatus.ACTIVE },
    { label: 'In Active', value: EnumStatus.INACTIVE },
  ]

  letterCodeOptions: Array<Dropdown> = []

  sortOptions: Array<Dropdown> = [
    { label: 'Terbaru', value: EnumSort.DESC },
    { label: 'Terlama', value: EnumSort.ASC },
  ]

  parameters: IParams = {
    page: 1,
    per_page: 10,
    keyword: '',
    status: this.statusOptions[0],
    letter_code: this.letterCodeOptions[-1],
    sk_start_at: '',
    sk_end_at: '',
    sort: this.sortOptions[0],
  }

  headers: Array<string | DataObject> = [
    {
      title: 'No.',
      customStyle: {
        minWidth: '48px',
        maxWidth: '48px',
      },
    },
    'Nama Driver',
    'No. Telepon',
    '3LC',
    'SK Start Date',
    'SK End Date',
    'Status',
    'Action',
    '',
  ]

  perPageOptions: Dropdown[] = [
    { label: '10', value: 10 },
    { label: '50', value: 50 },
    { label: '100', value: 100 }
  ]

  manageCaptainList: Array<
    Array<number | string | Array<string | number | boolean> | DataObject>
  > = []

  created(): void {
    this.fetchManageCaptain()
    this.routeController.getLetterCodes()
  }

  get params(): Record<string, string | number> {
    if (this.parameters.sk_start_at && !this.parameters.sk_end_at) {
      this.parameters.sort = this.sortOptions[1]
    } else if (this.parameters.sk_end_at && !this.parameters.sk_start_at) {
      this.parameters.sort = this.sortOptions[0]
    }

    return {
      page: this.parameters.page,
      per_page: this.parameters.per_page,
      keyword: encodeURIComponent(this.parameters.keyword),
      status: this.parameters.status.value,
      letter_code: this.parameters.letter_code?.value || '',
      sk_start_date: this.parameters.sk_start_at,
      sk_end_date: this.parameters.sk_end_at,
      sort: this.parameters.sort.value,
    }
  }

  private fetchManageCaptain(reset?: boolean): void {
    if (reset) this.parameters.page = 1
    controller.getAll(this.params)
  }

  private fetchManageCaptainPerPage(value: number): void {
    this.parameters.per_page = value
    this.fetchManageCaptain(true)
  }

  private onResetFilterBox(): void {
    this.popupFilterVisible = false
    this.parameters.status = this.statusOptions[0]
    this.parameters.letter_code = this.letterCodeOptions[-1]
    this.parameters.sk_start_at = ''
    this.parameters.sk_end_at = ''
    this.onFilterList()
  }

  private onFilterList = Utils.debounce(() => {
    this.fetchManageCaptain(true)
  }, 400)

  private routeTo(id: number, routerName: string): Location {
    return {
      name: routerName,
      params: { id: String(id) }
    }
  }

  private setBadgeStyle(status: string): string {
    if (status.toUpperCase() === EnumStatus.ACTIVE) {
      return 'success'
    } else if (status.toUpperCase() === EnumStatus.INACTIVE) {
      return 'error'
    }
    return ''
  }

  private setLetterCodeData(letterCode: Array<string>): string {
    if (
      letterCode.length === 0 ||
      (letterCode.length === 1 && letterCode[0] === '')
    ) {
      return '-'
    } else if (letterCode.length > 5) {
      const payload = letterCode.slice(0, 5)
      return `${payload.join(', ')}, ...`
    }

    return letterCode.join(', ')
  }

  private indexTable(idx: number): string {
    const getCountData = (this.parameters.page - 1) * this.parameters.per_page
    return String(getCountData + idx + 1)
  }

  private isPeriodComplete(endDate?: Date): boolean {
    return (
      dayjs().isAfter(dayjs(endDate)) && !dayjs().isSame(dayjs(endDate), 'day')
    )
  }

  @Watch('parameters', { deep: true })
  onFilterParamChange(): void {
    let count = 0

    if (this.parameters.status.value) count++
    if (this.parameters.letter_code) count++
    if (this.parameters.sk_start_at) count++
    if (this.parameters.sk_end_at) count++

    this.popupFilterVisible = false
    this.appliedFilter = count
  }

  @Watch('routeController.letterCodes')
  onLetterCodesChanged(data: LetterCode[]): void {
    this.letterCodeOptions = data.map((route) => {
      return {
        label: <string>route.lc,
        value: <string>route.lc
      }
    })
  }

  @Watch('controller.dataManageCaptainList')
  setDataManageCaptainList(data: ManageCaptain[]): void {
    this.manageCaptainList = data.map((item, index) => {
      return [
        {
          value: this.indexTable(index),
          customStyle: {
            minWidth: '48px',
            maxWidth: '48px',
          },
        },
        item.fullname || '',
        item.phoneNumber || '',
        item.letterCodes || [],
        item.skStartDate ? dayjs(item.skStartDate)
          .locale(dayjsID)
          .format('DD MMM YYYY') : '',
        item.skEndDate ? dayjs(item.skEndDate)
          .locale(dayjsID)
          .format('DD MMM YYYY') : '',
        item.status || '',
        item.courierCaptainId || '',
        [item.courierCaptainId || '', this.isPeriodComplete(item.skEndDate)],
      ]
    })
  }
}
