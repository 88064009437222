import { container } from 'tsyringe'
import Vue from 'vue'
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import store from '@/app/ui/store'
import { EventBus, EventBusConstants, Utils } from '@/app/infrastructures/misc'
import {
  ManageCaptain,
  ManageCaptainDetail,
  ManageCaptains,
} from '@/domain/entities/ManageCaptain'
import { ManageCaptainPresenter } from '../presenters/ManageCaptainPresenter'
import { Pagination } from '@/domain/entities/Pagination'
import {
  CreateManageCaptainApiRequest,
  UpdateManageCaptainApiRequest,
} from '@/data/payload/api/ManageCaptainRequest'

export interface ManageCaptainPayload {
  courier_id: number
  letter_codes: Array<string>
  sk_start_date: string
  sk_end_date: string
}

export interface ManageCaptainEditPayload {
  id: string
  letter_codes: string[]
  sk_start_date: string
  sk_end_date: string
}

export interface ManageCaptainState {
  isLoading: boolean
  isAddManageCaptainSuccess: boolean
  isEditManageCaptainSuccess: boolean
  dataManageCaptainList: ManageCaptain[]
  dataManageCaptain: ManageCaptainDetail
  paginationData: Pagination
}

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: 'manage-captain',
})
class ManageCaptainController extends VuexModule implements ManageCaptainState {
  private presenter: ManageCaptainPresenter = container.resolve(
    ManageCaptainPresenter
  )
  public isLoading = false
  public isAddManageCaptainSuccess = false
  public isEditManageCaptainSuccess = false
  public dataManageCaptainList = [new ManageCaptain()]
  public dataManageCaptain = new ManageCaptainDetail()
  public paginationData = new Pagination()

  @Action({ rawError: true })
  public getAll(params: Record<string, string | number>): void {
    this.setLoading(true)

    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getAll(formattedParams)
      .then(res => {
        if (res.data) this.setDataManageCaptainList(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Manage Captain List Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getDetail(id: number): void {
    this.setLoading(true)

    this.presenter
      .get(id)
      .then(res => {
        if (res) {
          this.setDataManageCaptain(res)
        }
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Detail Manage Captain Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public create(payload: ManageCaptainPayload): void {
    this.setLoading(true)
    this.presenter
      .create(
        new CreateManageCaptainApiRequest(
          payload.courier_id,
          payload.letter_codes,
          payload.sk_start_date,
          payload.sk_end_date
        )
      )
      .then(res => {
        this.setAddManageCaptainSuccess(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Create Manage Captain Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
        this.setAddManageCaptainSuccess(false)
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public update(payload: ManageCaptainEditPayload): void {
    this.setLoading(true)
    this.presenter
      .update(
        payload.id,
        new UpdateManageCaptainApiRequest(
          payload.letter_codes,
          payload.sk_start_date,
          payload.sk_end_date
        )
      )
      .then(res => {
        this.setEditManageCaptainSuccess(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Update Manage Captain Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
        this.setEditManageCaptainSuccess(false)
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Mutation
  private setLoading(bool: boolean): void {
    this.isLoading = bool
  }

  @Mutation
  private setDataManageCaptainList(list: ManageCaptains): void {
    this.dataManageCaptainList = <ManageCaptain[]>list.data
    this.paginationData = <Pagination>list.pagination
  }

  @Mutation
  private setDataManageCaptain(data: ManageCaptainDetail): void {
    this.dataManageCaptain = data
  }

  @Mutation
  public setAddManageCaptainSuccess(bool: boolean): void {
    this.isAddManageCaptainSuccess = bool
  }

  @Mutation
  public setEditManageCaptainSuccess(bool: boolean): void {
    this.isEditManageCaptainSuccess = bool
  }
}

export default getModule(ManageCaptainController)
