

























import { Component, Prop, Vue } from 'vue-property-decorator'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import { IOption } from '@/app/ui/components/PaginationNav/index.vue'

@Component({
  components: { DropdownSelect },
})
export default class PerPagePagination extends Vue {
  @Prop({ default: () => [] }) private perPageOptions!: IOption[]
  @Prop({ default: 0 }) private perPageValue!: number
  @Prop({ default: 0 }) private totalItem!: number | string
  @Prop({ default: false }) private isDisabled!: boolean
  @Prop({ default: 'en' }) private lang!: 'en' | 'id'

  localeText = {
    id: [
      'Menampilkan',
      'dari',
      'Data'
    ],
    en: [
      'Show',
      'of',
      'data'
    ]
  }

  selected = this.options[0]

  onChangePerPageOption(option: IOption): void {
    this.$emit('onChangeOption', option)
  }

  get options(): IOption[] {
    if (this.perPageOptions.length === 0) {
      return [{ label: '10', value: 10 }]
    }

    return this.perPageOptions
  }
}
