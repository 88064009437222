import {
  CreateManageCaptainRequestInterface,
  UpdateManageCaptainRequestInterface,
} from '../contracts/ManageCaptainRequest'

export class CreateManageCaptainApiRequest
  implements CreateManageCaptainRequestInterface {
  private courier_id?: number
  private letter_codes?: Array<string>
  private sk_start_date?: string
  private sk_end_date?: string

  constructor(
    courierID?: number,
    letterCodes?: Array<string>,
    skStartDate?: string,
    skEndDate?: string
  ) {
    this.courier_id = courierID
    this.letter_codes = letterCodes
    this.sk_start_date = skStartDate
    this.sk_end_date = skEndDate
  }

  public toPayload(): string {
    return JSON.stringify({
      courier_id: this.courier_id,
      letter_codes: this.letter_codes,
      sk_start_date: this.sk_start_date,
      sk_end_date: this.sk_end_date,
    })
  }
}

export class UpdateManageCaptainApiRequest
  implements UpdateManageCaptainRequestInterface {
  private letter_codes?: Array<string>
  private sk_start_date?: string
  private sk_end_date?: string

  constructor(
    letterCodes?: Array<string>,
    skStartDate?: string,
    skEndDate?: string
  ) {
    this.letter_codes = letterCodes
    this.sk_start_date = skStartDate
    this.sk_end_date = skEndDate
  }

  public toPayload(): string {
    return JSON.stringify({
      letter_codes: this.letter_codes,
      sk_start_date: this.sk_start_date,
      sk_end_date: this.sk_end_date,
    })
  }
}
