
















































import { Component, Prop, Vue } from 'vue-property-decorator'
import ExpandIcon from '@/app/ui/assets/expand_icon.vue'

@Component({
  components: { ExpandIcon },
})
export default class PerPagePagination extends Vue {
  @Prop() private page!: number | string
  @Prop() private totalItem!: number | string
  @Prop() private currentPage!: number
  @Prop() private totalPages!: number
  @Prop({default: 'en'}) private lang!: 'en' | 'id'

  fetchOnChangeIfNumber(eventObject: KeyboardEvent): void {
    const eventTarget = <HTMLInputElement>eventObject.target

    if (Number(eventTarget.value) <= 0) return

    if (
      eventObject.key === 'Enter' &&
      eventTarget.value.length !== 0 &&
      !isNaN(+eventTarget.value) &&
      +eventTarget.value >= 1
    ) {
      this.$emit('input', eventTarget.value)
    }

    this.$emit('keydown', eventObject)
  }

  private decrease(): void {
    this.$emit(
      'input',
      Number(this.page) > 1 ? Number(this.page) - 1 : Number(this.page)
    )
  }

  private increase(): void {
    this.$emit(
      'input',
      Number(this.page) < this.totalPages
        ? Number(this.page) + 1
        : Number(this.page)
    )
  }

  private handleInput(event: { target: HTMLInputElement }): void {
    if (event.target.value !== '' && +event.target.value > this.totalPages) {
      event.target.value = this.totalPages + ''
    }
    if (event.target.value === '0' || event.target.value === '') {
      event.target.value = ''
    }
  }

  private keyPressOnlyNumber = (evt: KeyboardEvent): void | boolean => {
    const charCode: number = evt.keyCode || +evt.code
    if (![49, 50, 51, 52, 53, 54, 55, 56, 57, 48].includes(charCode)) {
      evt.preventDefault()
    }
    return true
  }
}
